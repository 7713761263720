


























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';

@Component({
    components: {
        ModalWrapper,
    },
})
export default class ConfirmPopup extends Vue {
    @Prop({
        type: Function,
        required: true,
    })
    onClose!: () => void;

    @Prop({
        type: String,
        default: 'Confirm',
    })
    title!: string;

    @Prop({
        type: String,
        default: 'Close',
    })
    closeBtnText!: string;

    @Prop({
        type: String,
        default: 'Cancel',
    })
    cancelBtnText!: string;

    @Prop({
        type: Boolean,
        default: true,
    })
    useDefaultCancelMethod!: boolean;

    @Prop({
        type: Function,
        default: () => { },
    })
    onCancel!: () => void;

    cancel() {
        if (this.useDefaultCancelMethod) {
            this.$router.go(-1);
        }
        this.onCancel();
    }

    close() {
        this.onClose();
    }
}
